<template>
    <button @click="onClick()" class="btn"
        :style="{ 'background-color': bgColor, 'color': textColor, 'border-color': borderColor }"><font-awesome-icon
            :icon="icon" /> {{ $t(text) }}
    </button>
</template>

<script>
export default {
    name: 'button',
    props: ['icon', 'text', 'bgColor', 'textColor', 'borderColor'],
    methods: {
        onClick() {
            this.$emit('btnClick');
        }
    }
}
</script>

<style scoped></style>