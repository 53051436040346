<template>
    <div class="page-content" :class="{ 'with-filter': showFilter, 'with-case': showAddCase }">
        <GMap />
        <div class="space"></div>
        <!-- news Feed --> 
        <section class="news_Feed mt-5 mb-5 pt-3"  v-if="post_news.length">
            <div class="container">
                <div class="title_text text-center">
                    <h6>{{ category_box_1.subtitle?.value }} </h6>
                    <h2>{{ category_box_1.title?.value }}</h2>
                </div>
                <div class="row m-0 mt-5">
                    <div class="col-lg-4 col-md-6 mb-3 px-3" v-for="post_new in post_news" :key="post_new.id">
                        <div class="new_box">
                            <img :src="post_new.featured_image_url" alt="">
                            <h6>{{ post_new.date }}</h6>
                            <h3>{{ post_new.title }}</h3>
                            <h4>{{ post_new.brief }}</h4>
                            <router-link :to="'/post/' + post_new.slug" class="See_More">
                                {{ $t('news.read_more') }}
                                <font-awesome-icon icon="a-solid fa-arrow-right-long"></font-awesome-icon>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-5">
                    <a :href="'/category/news'" class="btn_color"> {{ $t("news.show_more") }} <font-awesome-icon
                            icon="a-solid fa-arrow-right-long"></font-awesome-icon></a>
                </div>
            </div>
        </section>
        <!-- Videos -->
        <section class="news_Feed mt-5 mb-5 videos" v-if="video_posts.length">
            <div class="container">
                <div class="title_text text-center">
                    <h6>{{ category_box_2.subtitle?.value }} </h6>
                    <h2>{{ category_box_2.title?.value }}</h2>
                </div>
                <div class="row m-0 mt-5">
                
                    <div class="col-lg-4 col-md-6 mb-3 px-3" v-for="video_post in video_posts" :key="video_post.id">
                        <div class="new_box">
                            <img :src="video_post.featured_image_url" alt="">
                            <div class="icon_video">
                                <font-awesome-icon icon="a-solid fa-play"></font-awesome-icon>
                            </div>
                            <div class="px-4  pb-4">
                                <h3>{{ video_post.title }}</h3>
                                <h4>{{ video_post.brief }}</h4>
                                <router-link :to="'/post/' + video_post.slug" class="See_More">
                                    {{ $t('news.read_more') }}
                                    <font-awesome-icon icon="a-solid fa-arrow-right-long"></font-awesome-icon>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-5">
                    <a :href="'/category/multimedia'" class="btn_color">  {{ $t("news.show_more") }}
                        <font-awesome-icon icon="a-solid fa-arrow-right-long"></font-awesome-icon>
                    </a>
                </div>
            </div>
        </section>
        <!-- About us -->
        <section class="about_us mb-5 pb-5">
            <div class="container mb-5">
                <div class="row m-0 section_text">
                    <div class="col-md-6 text_about">
                        <h3>{{ sections_about.title?.value }}</h3>
                        <p>{{ sections_about.text?.value }}</p>
                    </div>
                    <div class="col-md-6 form_text_about">
                        <form @submit.prevent="subscribeNewsletter()">
                            <h4>{{homepage_subscribe_text.title?.value}}</h4>
                            <h6>{{homepage_subscribe_text.text?.value}} </h6>
                            <div class="col-12">
                                <div class="form-group">
                                    <input type="text" placeholder="Your Name" class="form-control" v-model="name">
                                </div>
                                <div class="form-group">
                                    <input type="email" placeholder="Email" class="form-control" v-model="email">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="About" class="form-control" v-model="about">
                                </div>
                                <div class="form-group text-center">
                                    <button type="submit" class="btn btn_sumit">{{ $t("Send_Inquiry") }}</button><br>
                                    <a href="/contact" class="See_More"> {{ $t("Get_in_touch") }} <font-awesome-icon
                                            icon="a-solid fa-arrow-right-long"></font-awesome-icon></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import GMap from "../components/GMap.vue";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});
const base_url = process.env.VUE_APP_BASE_URL;

export default {
    name: "Home",
    components: {
        GMap,
    },
    props: {
        showAddCase: Boolean,
    },
    data: function () {
        return {
            sections_about: [],
            name: "",
            email: "",
            about: "",
            category_box_1: [],
            post_news: [],
            category_box_2: [],
            video_posts: [],
            storage_url: process.env.VUE_APP_BASE_URL_WEB + "/storage/",
            homepage_subscribe_text:[],
            main_url: process.env.VUE_APP_BASE_URL_WEB
        };
    },
    beforeMount() {
        this.about_sections();
        this.get_category_box_1();
        this.get_category_box_2();
        this.get_homepage_subscribe_text();
    },
    methods: {
        about_sections() {
            var params = {
                slug: "about_us_page_text_box",
                locale: localStorage.getItem('lang')
            };
            axios
                .get(base_url + "/getSectionBySlug", { params: params })

                .then((response) => {
                    this.sections_about = response.data.data;
                })
                .catch((error) => {
                    let res = error.response.data;
                    toaster.error(res.message);
                });
        },
        subscribeNewsletter() {
            this.loading = true;
            var params = {
                locale: localStorage.getItem("lang"),
                name: this.name,
                email: this.email,
                about: this.about,
            };
            axios
                .post(base_url + "/subscribeNewsletter", params)
                .then((response) => {
                    toaster.success(response.data.message);
                    this.resetForm();
                })
                .catch((error) => {
                    let res = error.response;
                    let error_details = "";
                    // toaster.error(res.data.message);
                    for (let key in res.data.data) {
                        error_details = `${res.data.data[key]}`;
                        toaster.error(error_details);
                    }
                })
                .finally(() => (this.loading = false));
        },
        /* news */
        get_category_box_1() {
            var params = {
                slug: "category_box_1",
                locale: localStorage.getItem('lang'),
            };
            axios
                .get(base_url + "/getSectionBySlug", { params: params })

                .then((response) => {
                    this.category_box_1 = response.data.data;
                    // localStorage.setItem("news_id", JSON.stringify(response.data.data.category_id?.value));
                    this.news_id = response.data.data.category_id?.section_id
                    this.get_post_news(JSON.stringify(response.data.data.category_id?.section_id));
                })
                .catch((error) => {
                    let res = error.response.data.data;
                    toaster.error(res.message);
                });
        },
        get_post_news() {
            var params = {
                locale: localStorage.getItem('lang'),
            };

            axios.get(base_url + "/get_featured_posts_by_category/" + this.news_id, { params: params })
                .then((response) => {
                    this.post_news = response.data.data.data;
                })
                .catch((error) => {
                    let res = error.response.data.data;
                    toaster.error(res.message);
                });
        },
        /* video */
        get_category_box_2() {
            var params = {
                slug: "category_box_2",
                locale: localStorage.getItem('lang'),
            };
            axios
                .get(base_url + "/getSectionBySlug", { params: params })

                .then((response) => {
                    this.category_box_2 = response.data.data;
                    this.video_id = response.data.data.category_id?.section_id
                    this.get_post_videos(JSON.stringify(response.data.data.category_id?.section_id));
                })
                .catch((error) => {
                    let res = error.response.data.data;
                    toaster.error(res.message);
                });
        },
        get_post_videos() {
            var params = {
                locale: localStorage.getItem('lang'),
            };
            axios.get(base_url + "/get_featured_posts_by_category/" + this.video_id, { params: params })
                .then((response) => {
                    this.video_posts = response.data.data.data;
                })
                .catch((error) => {
                    let res = error.response.data.data;
                    toaster.error(res.message);
                });
        },
        get_homepage_subscribe_text() {
            var params = {
                slug: "homepage_subscribe_box",
                locale: localStorage.getItem('lang')
            };
            axios
                .get(base_url + "/getSectionBySlug", { params: params })

                .then((response) => {
                    this.homepage_subscribe_text = response.data.data;
                })
                .catch((error) => {
                    let res = error.response.data;
                    toaster.error(res.message);
                });
        },
    },
}
</script>

<style scoped>
.btn.panel-toggle-btn {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem;
    border-radius: 5rem;
    z-index: 100;
    box-shadow: 0 0 20px -10px #222;
}

.page-content {
    padding: 0;
}

.space {
    background-color: #AEAFD1;
    min-height: 48px;
}

.form-control {
    color: #fff !important;
}
</style>